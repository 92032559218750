<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">

                <Toast />
                <Toolbar class="mb-4">

                    <template v-slot:end>
                        <!-- <FileUpload
                mode="basic"
                accept="image/*"
                :maxFileSize="1000000"
                label="Import"
                chooseLabel="Import"
                class="mr-2 inline-block"
              />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportarTabela()" />
                    </template>
                    <h5 class="mt-4">Relatório Consolidado</h5>
                </Toolbar>


                <div class="m-5" style="text-align: center;">
                    <Dropdown class="mr-2" id="status" v-model="id_usina" filter :options="usinas" optionLabel="label"
                        optionValue="value" placeholder="Filtrar por Usina">
                    </Dropdown>

                    <Dropdown class="mr-2" id="status" v-model="mes" :options="meses" optionLabel="label"
                        optionValue="value" placeholder="Filtrar por Mês">
                    </Dropdown>

                    <Dropdown class="mr-2" id="status" v-model="ano" :options="anos" optionLabel="label" optionValue="value"
                        placeholder="Filtrar por Ano">
                    </Dropdown>

                    <Button label="Gerar Relatório" :loading="loading" icon="pi pi-search" class="p-button-secondary p-button-sm"
                        @click="gerar()" />

                </div>
                <div style="width: 100; overflow: auto; ">
                    <table class="table" id="consolidado" style="width: 100%;">
                        <thead>
                            <tr>
                                <th>{{ consolidado.usina }}</th>
                                <th>{{ consolidado[0].mes_referencia }}</th>
                                <th>{{ consolidado[1].mes_referencia }}</th>
                                <th>{{ consolidado[2].mes_referencia }}</th>
                                <th>{{ consolidado[3].mes_referencia }}</th>
                                <th>{{ consolidado[4].mes_referencia }}</th>
                                <th>{{ consolidado[5].mes_referencia }}</th>
                                <th>{{ consolidado[6].mes_referencia }}</th>
                                <th>{{ consolidado[7].mes_referencia }}</th>
                                <th>{{ consolidado[8].mes_referencia }}</th>
                                <th class="mes_selecionado">{{ consolidado[9].mes_referencia }}</th>
                                <th>Total</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="13" style="font-weight: bold;padding-top: 20px;">Dados Técnicos</td>
                            </tr>
                            <tr>
                                <td>Volume (MWp)</td>
                                <td>{{ consolidado[0].volume_mwp }}</td>
                                <td>{{ consolidado[1].volume_mwp }}</td>
                                <td>{{ consolidado[2].volume_mwp }}</td>
                                <td>{{ consolidado[3].volume_mwp }}</td>
                                <td>{{ consolidado[4].volume_mwp }}</td>
                                <td>{{ consolidado[5].volume_mwp }}</td>
                                <td>{{ consolidado[6].volume_mwp }}</td>
                                <td>{{ consolidado[7].volume_mwp }}</td>
                                <td>{{ consolidado[8].volume_mwp }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].volume_mwp }}</td>
                                <td>{{ consolidado.total_volume_mwp }}</td>

                            </tr>

                            <tr>
                                <td>Volume (MWac)</td>
                                <td>{{ consolidado[0].volume_mwac }}</td>
                                <td>{{ consolidado[1].volume_mwac }}</td>
                                <td>{{ consolidado[2].volume_mwac }}</td>
                                <td>{{ consolidado[3].volume_mwac }}</td>
                                <td>{{ consolidado[4].volume_mwac }}</td>
                                <td>{{ consolidado[5].volume_mwac }}</td>
                                <td>{{ consolidado[6].volume_mwac }}</td>
                                <td>{{ consolidado[7].volume_mwac }}</td>
                                <td>{{ consolidado[8].volume_mwac }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].volume_mwac }}</td>
                                <td>{{ consolidado.total_volume_mwac }}</td>
                            </tr>

                            <tr>
                                <td colspan="12" style="border-top: 1px solid #ccc; padding-top: 20px; font-weight: bold;">
                                    Tarifas</td>
                            </tr>
                            <tr>
                                <td>TUSD (R$/MWh)</td>
                                <td>{{ consolidado[0].tusd_valor }}</td>
                                <td>{{ consolidado[1].tusd_valor }}</td>
                                <td>{{ consolidado[2].tusd_valor }}</td>
                                <td>{{ consolidado[3].tusd_valor }}</td>
                                <td>{{ consolidado[4].tusd_valor }}</td>
                                <td>{{ consolidado[5].tusd_valor }}</td>
                                <td>{{ consolidado[6].tusd_valor }}</td>
                                <td>{{ consolidado[7].tusd_valor }}</td>
                                <td>{{ consolidado[8].tusd_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].tusd_valor }}</td>
                                <td></td>

                            </tr>

                            <tr>
                                <td>TE (R$/MWh)</td>
                                <td>{{ consolidado[0].te_valor }}</td>
                                <td>{{ consolidado[1].te_valor }}</td>
                                <td>{{ consolidado[2].te_valor }}</td>
                                <td>{{ consolidado[3].te_valor }}</td>
                                <td>{{ consolidado[4].te_valor }}</td>
                                <td>{{ consolidado[5].te_valor }}</td>
                                <td>{{ consolidado[6].te_valor }}</td>
                                <td>{{ consolidado[7].te_valor }}</td>
                                <td>{{ consolidado[8].te_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].te_valor }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>CUSD (R$/kW)</td>
                                <td>{{ consolidado[0].cusd_valor }}</td>
                                <td>{{ consolidado[1].cusd_valor }}</td>
                                <td>{{ consolidado[2].cusd_valor }}</td>
                                <td>{{ consolidado[3].cusd_valor }}</td>
                                <td>{{ consolidado[4].cusd_valor }}</td>
                                <td>{{ consolidado[5].cusd_valor }}</td>
                                <td>{{ consolidado[6].cusd_valor }}</td>
                                <td>{{ consolidado[7].cusd_valor }}</td>
                                <td>{{ consolidado[8].cusd_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].cusd_valor }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>TUSD G (R$/kW)</td>
                                <td>{{ consolidado[0].tusdg_valor }}</td>
                                <td>{{ consolidado[1].tusdg_valor }}</td>
                                <td>{{ consolidado[2].tusdg_valor }}</td>
                                <td>{{ consolidado[3].tusdg_valor }}</td>
                                <td>{{ consolidado[4].tusdg_valor }}</td>
                                <td>{{ consolidado[5].tusdg_valor }}</td>
                                <td>{{ consolidado[6].tusdg_valor }}</td>
                                <td>{{ consolidado[7].tusdg_valor }}</td>
                                <td>{{ consolidado[8].tusdg_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].tusdg_valor }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PIS/COFINS (%)</td>
                                <td>{{ consolidado[0].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[1].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[2].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[3].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[4].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[5].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[6].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[7].pis_cofins_porcentagem }}</td>
                                <td>{{ consolidado[8].pis_cofins_porcentagem }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].pis_cofins_porcentagem }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>ICMS (%)</td>
                                <td>{{ consolidado[0].icms_porcentagem }}</td>
                                <td>{{ consolidado[1].icms_porcentagem }}</td>
                                <td>{{ consolidado[2].icms_porcentagem }}</td>
                                <td>{{ consolidado[3].icms_porcentagem }}</td>
                                <td>{{ consolidado[4].icms_porcentagem }}</td>
                                <td>{{ consolidado[5].icms_porcentagem }}</td>
                                <td>{{ consolidado[6].icms_porcentagem }}</td>
                                <td>{{ consolidado[7].icms_porcentagem }}</td>
                                <td>{{ consolidado[8].icms_porcentagem }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].icms_porcentagem }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="border-top: 10px solid #eee;padding: 0;" colspan="12"></td>
                            </tr>
                            <tr>
                                <td>Tarifa Cheia (R$/MWh)</td>
                                <td>{{ consolidado[0].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[1].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[2].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[3].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[4].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[5].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[6].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[7].tarifa_cheia_valor }}</td>
                                <td>{{ consolidado[8].tarifa_cheia_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].tarifa_cheia_valor }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Demanda (R$/MW)</td>
                                <td>{{ consolidado[0].demanda_valor }}</td>
                                <td>{{ consolidado[1].demanda_valor }}</td>
                                <td>{{ consolidado[2].demanda_valor }}</td>
                                <td>{{ consolidado[3].demanda_valor }}</td>
                                <td>{{ consolidado[4].demanda_valor }}</td>
                                <td>{{ consolidado[5].demanda_valor }}</td>
                                <td>{{ consolidado[6].demanda_valor }}</td>
                                <td>{{ consolidado[7].demanda_valor }}</td>
                                <td>{{ consolidado[8].demanda_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].demanda_valor }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Demanda TUSD G (R$/MW)</td>
                                <td>{{ consolidado[0].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[1].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[2].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[3].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[4].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[5].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[6].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[7].demanda_tusdg_valor }}</td>
                                <td>{{ consolidado[8].demanda_tusdg_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].demanda_tusdg_valor }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="border-top: 10px solid #eee;padding: 0;" colspan="12"></td>
                            </tr>
                            <tr>
                                <td>CUSD (R$/MWh)</td>
                                <td>{{ consolidado[0].cusd_calculado }}</td>
                                <td>{{ consolidado[1].cusd_calculado }}</td>
                                <td>{{ consolidado[2].cusd_calculado }}</td>
                                <td>{{ consolidado[3].cusd_calculado }}</td>
                                <td>{{ consolidado[4].cusd_calculado }}</td>
                                <td>{{ consolidado[5].cusd_calculado }}</td>
                                <td>{{ consolidado[6].cusd_calculado }}</td>
                                <td>{{ consolidado[7].cusd_calculado }}</td>
                                <td>{{ consolidado[8].cusd_calculado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].cusd_calculado }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>TUSD G (R$/MWh)</td>
                                <td>{{ consolidado[0].tusdg_calculado }}</td>
                                <td>{{ consolidado[1].tusdg_calculado }}</td>
                                <td>{{ consolidado[2].tusdg_calculado }}</td>
                                <td>{{ consolidado[3].tusdg_calculado }}</td>
                                <td>{{ consolidado[4].tusdg_calculado }}</td>
                                <td>{{ consolidado[5].tusdg_calculado }}</td>
                                <td>{{ consolidado[6].tusdg_calculado }}</td>
                                <td>{{ consolidado[7].tusdg_calculado }}</td>
                                <td>{{ consolidado[8].tusdg_calculado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].tusdg_calculado }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>ICMS (R$/MWh)</td>
                                <td>{{ consolidado[0].icms_calculado }}</td>
                                <td>{{ consolidado[1].icms_calculado }}</td>
                                <td>{{ consolidado[2].icms_calculado }}</td>
                                <td>{{ consolidado[3].icms_calculado }}</td>
                                <td>{{ consolidado[4].icms_calculado }}</td>
                                <td>{{ consolidado[5].icms_calculado }}</td>
                                <td>{{ consolidado[6].icms_calculado }}</td>
                                <td>{{ consolidado[7].icms_calculado }}</td>
                                <td>{{ consolidado[8].icms_calculado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].icms_calculado }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="border-top: 10px solid #eee;padding: 0;" colspan="12"></td>
                            </tr>
                            <tr>
                                <td>Valor por Crédito (R$/MWh)</td>
                                <td>{{ consolidado[0].valor_por_credito }}</td>
                                <td>{{ consolidado[1].valor_por_credito }}</td>
                                <td>{{ consolidado[2].valor_por_credito }}</td>
                                <td>{{ consolidado[3].valor_por_credito }}</td>
                                <td>{{ consolidado[4].valor_por_credito }}</td>
                                <td>{{ consolidado[5].valor_por_credito }}</td>
                                <td>{{ consolidado[6].valor_por_credito }}</td>
                                <td>{{ consolidado[7].valor_por_credito }}</td>
                                <td>{{ consolidado[8].valor_por_credito }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].valor_por_credito }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Valor por Crédito Atualizado (R$/MWh)</td>
                                <td>{{ consolidado[0].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[1].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[2].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[3].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[4].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[5].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[6].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[7].valor_por_credito_atualizado }}</td>
                                <td>{{ consolidado[8].valor_por_credito_atualizado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].valor_por_credito_atualizado }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Índice de Reajuste O&M (agosto)</td>
                                <td>{{ consolidado[0].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[1].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[2].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[3].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[4].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[5].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[6].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[7].indice_reajuste_oem }}</td>
                                <td>{{ consolidado[8].indice_reajuste_oem }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].indice_reajuste_oem }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="12" style="border-top: 1px solid #ccc; padding-top: 20px; font-weight: bold;">
                                    Geração</td>
                            </tr>
                            <tr>
                                <td>Energia Contratada (MWh/mês)</td>
                                <td>{{ consolidado[0].energia_contratada }}</td>
                                <td>{{ consolidado[1].energia_contratada }}</td>
                                <td>{{ consolidado[2].energia_contratada }}</td>
                                <td>{{ consolidado[3].energia_contratada }}</td>
                                <td>{{ consolidado[4].energia_contratada }}</td>
                                <td>{{ consolidado[5].energia_contratada }}</td>
                                <td>{{ consolidado[6].energia_contratada }}</td>
                                <td>{{ consolidado[7].energia_contratada }}</td>
                                <td>{{ consolidado[8].energia_contratada }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].energia_contratada }}</td>
                                <td>{{ consolidado[0].energia_contratada }}</td>
                            </tr>
                            <tr>
                                <td>Energia Injetada (MWh/mês)</td>
                                <td>{{ consolidado[0].energia_injetada }}</td>
                                <td>{{ consolidado[1].energia_injetada }}</td>
                                <td>{{ consolidado[2].energia_injetada }}</td>
                                <td>{{ consolidado[3].energia_injetada }}</td>
                                <td>{{ consolidado[4].energia_injetada }}</td>
                                <td>{{ consolidado[5].energia_injetada }}</td>
                                <td>{{ consolidado[6].energia_injetada }}</td>
                                <td>{{ consolidado[7].energia_injetada }}</td>
                                <td>{{ consolidado[8].energia_injetada }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].energia_injetada }}</td>
                                <td>{{ consolidado[0].energia_injetada }}</td>
                            </tr>
                            <tr>
                                <td>Injetada vs. Contratada (%)</td>
                                <td>{{ consolidado[0].injetada_x_contratada }}</td>
                                <td>{{ consolidado[1].injetada_x_contratada }}</td>
                                <td>{{ consolidado[2].injetada_x_contratada }}</td>
                                <td>{{ consolidado[3].injetada_x_contratada }}</td>
                                <td>{{ consolidado[4].injetada_x_contratada }}</td>
                                <td>{{ consolidado[5].injetada_x_contratada }}</td>
                                <td>{{ consolidado[6].injetada_x_contratada }}</td>
                                <td>{{ consolidado[7].injetada_x_contratada }}</td>
                                <td>{{ consolidado[8].injetada_x_contratada }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].injetada_x_contratada }}</td>
                                <td>{{ consolidado[0].injetada_x_contratada }}</td>
                            </tr>
                            <tr>
                                <td colspan="12" style="border-top: 1px solid #ccc; padding-top: 20px; font-weight: bold;">
                                    Consumo</td>
                            </tr>
                            <tr>
                                <td>Energia Consumida (MWh/mês)</td>
                                <td>{{ consolidado[0].energia_consumida }}</td>
                                <td>{{ consolidado[1].energia_consumida }}</td>
                                <td>{{ consolidado[2].energia_consumida }}</td>
                                <td>{{ consolidado[3].energia_consumida }}</td>
                                <td>{{ consolidado[4].energia_consumida }}</td>
                                <td>{{ consolidado[5].energia_consumida }}</td>
                                <td>{{ consolidado[6].energia_consumida }}</td>
                                <td>{{ consolidado[7].energia_consumida }}</td>
                                <td>{{ consolidado[8].energia_consumida }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].energia_consumida }}</td>
                                <td>{{ consolidado[0].energia_consumida }}</td>
                            </tr>
                            <tr>
                                <td>Consumo vs. Contratado (%)</td>
                                <td>{{ consolidado[0].consumo_x_contratado }}</td>
                                <td>{{ consolidado[1].consumo_x_contratado }}</td>
                                <td>{{ consolidado[2].consumo_x_contratado }}</td>
                                <td>{{ consolidado[3].consumo_x_contratado }}</td>
                                <td>{{ consolidado[4].consumo_x_contratado }}</td>
                                <td>{{ consolidado[5].consumo_x_contratado }}</td>
                                <td>{{ consolidado[6].consumo_x_contratado }}</td>
                                <td>{{ consolidado[7].consumo_x_contratado }}</td>
                                <td>{{ consolidado[8].consumo_x_contratado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].consumo_x_contratado }}</td>
                                <td>{{ consolidado[0].consumo_x_contratado }}</td>
                            </tr>
                            <tr>
                                <td colspan="13" style="border-top: 1px solid #ccc; padding-top: 20px; font-weight: bold;">
                                    Compensação</td>
                            </tr>
                            <tr>
                                <td>Energia Compensada (MWh/mês)</td>
                                <td>{{ consolidado[0].energia_compensada }}</td>
                                <td>{{ consolidado[1].energia_compensada }}</td>
                                <td>{{ consolidado[2].energia_compensada }}</td>
                                <td>{{ consolidado[3].energia_compensada }}</td>
                                <td>{{ consolidado[4].energia_compensada }}</td>
                                <td>{{ consolidado[5].energia_compensada }}</td>
                                <td>{{ consolidado[6].energia_compensada }}</td>
                                <td>{{ consolidado[7].energia_compensada }}</td>
                                <td>{{ consolidado[8].energia_compensada }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].energia_compensada }}</td>
                                <td>{{ consolidado[0].energia_compensada }}</td>
                            </tr>
                            <tr>
                                <td>Compensado vs. Contratado (%)</td>
                                <td>{{ consolidado[0].compensada_x_contratada }}</td>
                                <td>{{ consolidado[1].compensada_x_contratada }}</td>
                                <td>{{ consolidado[2].compensada_x_contratada }}</td>
                                <td>{{ consolidado[3].compensada_x_contratada }}</td>
                                <td>{{ consolidado[4].compensada_x_contratada }}</td>
                                <td>{{ consolidado[5].compensada_x_contratada }}</td>
                                <td>{{ consolidado[6].compensada_x_contratada }}</td>
                                <td>{{ consolidado[7].compensada_x_contratada }}</td>
                                <td>{{ consolidado[8].compensada_x_contratada }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].compensada_x_contratada }}</td>
                                <td>{{ consolidado[0].compensada_x_contratada }}</td>
                            </tr>
                            <tr>
                                <td colspan="13" style="border-top: 1px solid #ccc; padding-top: 20px; font-weight: bold;">
                                    Faturamento</td>
                            </tr>
                            <tr>
                                <td>Valor do Contrato/Equipamento (R$)</td>
                                <td>{{ consolidado[0].valor_contratado }}</td>
                                <td>{{ consolidado[1].valor_contratado }}</td>
                                <td>{{ consolidado[2].valor_contratado }}</td>
                                <td>{{ consolidado[3].valor_contratado }}</td>
                                <td>{{ consolidado[4].valor_contratado }}</td>
                                <td>{{ consolidado[5].valor_contratado }}</td>
                                <td>{{ consolidado[6].valor_contratado }}</td>
                                <td>{{ consolidado[7].valor_contratado }}</td>
                                <td>{{ consolidado[8].valor_contratado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].valor_contratado }}</td>
                                <td>{{ consolidado[0].valor_contratado }}</td>
                            </tr>
                            <tr>
                                <td>Valor do Contrato Reajustado/Equipamento (R$)</td>
                                <td>{{ consolidado[0].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[1].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[2].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[3].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[4].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[5].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[6].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[7].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[8].valor_contrato_reajustado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[0].valor_contrato_reajustado }}</td>
                            </tr>
                            <tr>
                                <td>Valor de Terras Reajustado (R$)</td>
                                <td>{{ consolidado[0].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[1].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[2].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[3].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[4].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[5].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[6].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[7].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[8].valor_contrato_reajustado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].valor_contrato_reajustado }}</td>
                                <td>{{ consolidado[0].valor_contrato_reajustado }}</td>
                            </tr>
                            <tr>
                                <td>Faturamento Esperado 90% (R$)</td>
                                <td>{{ consolidado[0].faturamento_esperado }}</td>
                                <td>{{ consolidado[1].faturamento_esperado }}</td>
                                <td>{{ consolidado[2].faturamento_esperado }}</td>
                                <td>{{ consolidado[3].faturamento_esperado }}</td>
                                <td>{{ consolidado[4].faturamento_esperado }}</td>
                                <td>{{ consolidado[5].faturamento_esperado }}</td>
                                <td>{{ consolidado[6].faturamento_esperado }}</td>
                                <td>{{ consolidado[7].faturamento_esperado }}</td>
                                <td>{{ consolidado[8].faturamento_esperado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].faturamento_esperado }}</td>
                                <td>{{ consolidado[0].faturamento_esperado }}</td>
                            </tr>
                            <tr>
                                <td>Faturamento Realizado (R$)</td>
                                <td>{{ consolidado[0].faturamento_realizado }}</td>
                                <td>{{ consolidado[1].faturamento_realizado }}</td>
                                <td>{{ consolidado[2].faturamento_realizado }}</td>
                                <td>{{ consolidado[3].faturamento_realizado }}</td>
                                <td>{{ consolidado[4].faturamento_realizado }}</td>
                                <td>{{ consolidado[5].faturamento_realizado }}</td>
                                <td>{{ consolidado[6].faturamento_realizado }}</td>
                                <td>{{ consolidado[7].faturamento_realizado }}</td>
                                <td>{{ consolidado[8].faturamento_realizado }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].faturamento_realizado }}</td>
                                <td>{{ consolidado[0].faturamento_realizado }}</td>
                            </tr>
                            <tr>
                                <td colspan="13"></td>
                            </tr>
                            <tr>
                                <td>Locação de Equipamentos (R$)</td>
                                <td>{{ consolidado[0].locacao_equipamento }}</td>
                                <td>{{ consolidado[1].locacao_equipamento }}</td>
                                <td>{{ consolidado[2].locacao_equipamento }}</td>
                                <td>{{ consolidado[3].locacao_equipamento }}</td>
                                <td>{{ consolidado[4].locacao_equipamento }}</td>
                                <td>{{ consolidado[5].locacao_equipamento }}</td>
                                <td>{{ consolidado[6].locacao_equipamento }}</td>
                                <td>{{ consolidado[7].locacao_equipamento }}</td>
                                <td>{{ consolidado[8].locacao_equipamento }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].locacao_equipamento }}</td>
                                <td>{{ consolidado[0].locacao_equipamento }}</td>
                            </tr>
                            <tr>
                                <td>O&M (R$)</td>
                                <td>{{ consolidado[0].oem_valor }}</td>
                                <td> {{ consolidado[1].oem_valor }}</td>
                                <td>{{ consolidado[2].oem_valor }}</td>
                                <td>{{ consolidado[3].oem_valor }}</td>
                                <td>{{ consolidado[4].oem_valor }}</td>
                                <td>{{ consolidado[5].oem_valor }}</td>
                                <td>{{ consolidado[6].oem_valor }}</td>
                                <td>{{ consolidado[7].oem_valor }}</td>
                                <td>{{ consolidado[8].oem_valor }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].oem_valor }}</td>
                                <td>{{ consolidado[0].oem_valor }}</td>
                            </tr>
                            <tr>
                                <td>Fatura da UFV (R$)</td>
                                <td>{{ consolidado[0].fatura_ufv }}</td>
                                <td> {{ consolidado[1].fatura_ufv }}</td>
                                <td> {{ consolidado[2].fatura_ufv }}</td>
                                <td> {{ consolidado[3].fatura_ufv }}</td>
                                <td> {{ consolidado[4].fatura_ufv }}</td>
                                <td> {{ consolidado[5].fatura_ufv }}</td>
                                <td> {{ consolidado[6].fatura_ufv }}</td>
                                <td> {{ consolidado[7].fatura_ufv }}</td>
                                <td> {{ consolidado[8].fatura_ufv }}</td>
                                <td class="mes_selecionado"> {{ consolidado[9].fatura_ufv }}</td>
                                <td>{{ consolidado[0].fatura_ufv }}</td>
                            </tr>
                            <tr>
                                <td colspan="13" style="border-top: 1px solid #ccc; padding-top: 20px; font-weight: bold;">
                                    Reajuste Contrato/Equipamento (IPCA)</td>
                            </tr>
                            <tr>
                                <td>Data Base - {{ consolidado[0].data_base }}</td>
                                <td>{{ consolidado[0].valor_data_base }}</td>
                                <td> {{ consolidado[1].valor_data_base }}</td>
                                <td> {{ consolidado[2].valor_data_base }}</td>
                                <td> {{ consolidado[3].valor_data_base }}</td>
                                <td> {{ consolidado[4].valor_data_base }}</td>
                                <td> {{ consolidado[5].valor_data_base }}</td>
                                <td> {{ consolidado[6].valor_data_base }}</td>
                                <td> {{ consolidado[7].valor_data_base }}</td>
                                <td> {{ consolidado[8].valor_data_base }}</td>
                                <td class="mes_selecionado"> {{ consolidado[9].valor_data_base }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Data Atualização - {{ consolidado[0].data_atualizacao }}</td>
                                <td>{{ consolidado[0].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[1].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[2].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[3].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[4].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[5].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[6].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[7].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[8].valor_data_atualizacao }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].valor_data_atualizacao }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Índice</td>
                                <td>{{ consolidado[0].indice }}</td>
                                <td>{{ consolidado[1].indice }} </td>
                                <td>{{ consolidado[2].indice }} </td>
                                <td>{{ consolidado[3].indice }} </td>
                                <td>{{ consolidado[4].indice }} </td>
                                <td>{{ consolidado[5].indice }} </td>
                                <td>{{ consolidado[6].indice }} </td>
                                <td>{{ consolidado[7].indice }} </td>
                                <td>{{ consolidado[8].indice }} </td>
                                <td class="mes_selecionado">{{ consolidado[9].indice }} </td>
                                <td></td>
                            </tr>
<!--
                            <tr>
                                <td colspan="13" style="border-top: 1px solid #ccc; padding-top: 20px; font-weight: bold;">
                                    Reajuste Terras</td>
                            </tr>
                            <tr>
                                <td>Data Base - {{ consolidado[0].data_base }}</td>
                                <td>{{ consolidado[0].valor_data_base }}</td>
                                <td> {{ consolidado[1].valor_data_base }}</td>
                                <td> {{ consolidado[2].valor_data_base }}</td>
                                <td> {{ consolidado[3].valor_data_base }}</td>
                                <td> {{ consolidado[4].valor_data_base }}</td>
                                <td> {{ consolidado[5].valor_data_base }}</td>
                                <td> {{ consolidado[6].valor_data_base }}</td>
                                <td> {{ consolidado[7].valor_data_base }}</td>
                                <td> {{ consolidado[8].valor_data_base }}</td>
                                <td class="mes_selecionado"> {{ consolidado[9].valor_data_base }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Data Atualização - {{ consolidado[0].data_atualizacao }}</td>
                                <td>{{ consolidado[0].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[1].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[2].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[3].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[4].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[5].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[6].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[7].valor_data_atualizacao }}</td>
                                <td>{{ consolidado[8].valor_data_atualizacao }}</td>
                                <td class="mes_selecionado">{{ consolidado[9].valor_data_atualizacao }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Índice</td>
                                <td>{{ consolidado[0].indice }}</td>
                                <td>{{ consolidado[1].indice }} </td>
                                <td>{{ consolidado[2].indice }} </td>
                                <td>{{ consolidado[3].indice }} </td>
                                <td>{{ consolidado[4].indice }} </td>
                                <td>{{ consolidado[5].indice }} </td>
                                <td>{{ consolidado[6].indice }} </td>
                                <td>{{ consolidado[7].indice }} </td>
                                <td>{{ consolidado[8].indice }} </td>
                                <td class="mes_selecionado">{{ consolidado[9].indice }} </td>
                                <td></td>
                            </tr>-->
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            id_usina: "",
            loading:false,
            ano: "",
            mes: "",
            anos: [
                { label: "", value: "" },
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" },{ label: "2024", value: "2024" }],
            meses: [
                { label: "", value: "" },
                { label: "Janeiro", value: "01" },
                { label: "Fevereiro", value: "02" },
                { label: "Março", value: "03" },
                { label: "Abril", value: "04" },
                { label: "Maio", value: "05" },
                { label: "Junho", value: "06" },
                { label: "Julho", value: "07" },
                { label: "Agosto", value: "08" },
                { label: "Setembro", value: "09" },
                { label: "Outubro", value: "10" },
                { label: "Novembro", value: "11" },
                { label: "Dezembro", value: "12" },
            ],
            colunas: [
                { header: 'Usina', field: 'Mes_Competencia' },
                { header: 'Julho/2023', field: 'identificacao' },
                { header: 'Agosto/2023', field: 'unidade' },
                { header: 'Setembro/2023', field: 'valor_rateio' },
                { header: 'Outubro/2023', field: 'disponibilidade' },
                { header: 'Novembro/2023', field: 'custo_disp' },
                { header: 'Dezembro/2023', field: 'Data_Leitura_Anterior' },
                { header: 'Janeiro/2024', field: 'Data_Leitura_Atual' },
                { header: 'Fevereiro/2024', field: '' },
                { header: 'Março/2024', field: 'Consumo_Registrado_kWh' },
                { header: 'Abril/2024', field: 'Energia_injetada_kWh' },
                { header: 'Maio/2024', field: 'Energia_Compensada_2_kWh' },
                { header: 'Junho/2024', field: 'Energia_Compensada_3_kWh' },
            ],
            token: localStorage.getItem('token'),
            dado_pendente: [
                { label: "Limpar Filtro", value: "" },
                { label: "Contrato RE", value: "1" },
                { label: "Inscrição Imobiliária", value: "2" },
                { label: "Ordem SAP", value: "3" },
                { label: "Endereço", value: "4" },
                { label: "Área", value: "5" },
                { label: "Tipo", value: "6" },
                { label: "Regional", value: "7" },
                { label: "Regra Pagamento", value: "8" },
            ],
            parcelas: [
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
                { label: "6", value: 6 },
                { label: "7", value: 7 },
                { label: "8", value: 8 },
                { label: "9", value: 9 },
                { label: "10", value: 10 },
                { label: "11", value: 11 },
                { label: "12", value: 12 },
            ],
            dado: "",
            arquivo: "",
            economia: [],
            total_volume_mwp: 0,
            consolidado: [
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                },
                {
                    "mes_referencia": "",
                    "volume_mwp": "",
                    "volume_mwac": "",
                    "tusd_valor": "",
                    "te_valor": "",
                    "cusd_valor": "",
                    "tusdg_valor": "",
                    "pis_cofins_porcentagem": "",
                    "icms_porcentagem": "",
                    "tarifa_cheia_valor": "",
                    "demanda_valor": "",
                    "demanda_tusdg_valor": "",
                    "cusd_calculado": "",
                    "tusdg_calculado": "",
                    "icms_calculado": ""
                }
            ],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            unidade: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: 0,
            empresas: [],
            areas: [],
            regionais: [],
            tipos: [],
            estados: [],
            cidades: [],
            id_estado: "",
            id_cidade: "",
            id_empresa: "",
            id_unidade: "",
            parcela: "",
            id_tipo: "",
            data_referencia: "",
            data_vencimento: "",
            total: "",
            boleto: "",
            faturas: [],
            usinas: [],
        };
    },
    productService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.listarUsinas();

        //this.listarRelatorio();

    },
    methods: {
        exportarTabela() {
            var table = document.getElementById('consolidado'); // id of table
            var tableHTML = table.outerHTML;
            var fileName = 'Consolidado | ' + this.consolidado.usina + '_' + this.mes + '/' + this.ano + '.xls';

            var msie = window.navigator.userAgent.indexOf("MSIE ");

            // If Internet Explorer
            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
                var dummyFrame;
                document.body.appendChild(dummyFrame);
                dummyFrame.contentDocument.open('txt/html', 'replace');
                dummyFrame.contentDocument.write(tableHTML);
                dummyFrame.contentDocument.close();
                dummyFrame.focus();
                return dummyFrame.contentDocument.execCommand('SaveAs', true, fileName);
            }
            // Other browsers
            else {
                var a = document.createElement('a');
                tableHTML = tableHTML.replace(/ {2}/g, ''); // removes extra spaces
                a.href = 'data:application/vnd.ms-excel;charset=ISO-8859-1,' + encodeURIComponent(tableHTML);
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
        listarAreas() {
            this.axios
                .post("/areas/listar")
                .then((response) => {
                    console.log(response);
                    let options = [];
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["titulo"] };
                        options.push(option);
                    });
                    this.areas = options;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        trocarCidades() {
            console.log(this.unidade.id_estado);
            this.listarCidades(this.unidade.id_estado);
        },

        gerar() {
            this.loading = true;
            let dados = {
                id_usina: this.id_usina,
                ano: this.ano,
                mes: this.mes
            }
            this.axios
                .post("/eum/consolidado", dados)
                .then((response) => {

                    this.consolidado = response.data.dados;
                    console.log(this.consolidado)

                    this.loading = false;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                    this.loading = false;
                });
        },
        listarUsinas() {

            this.axios
                .post("/usinas/listar")
                .then((response) => {

                    let options = [];

                    let res = response.data.dados;
                    Object.keys(res).forEach(ley => {
                        console.log(ley);
                        let option = { value: res[ley]["id"], label: res[ley]["razao_social"] };
                        options.push(option);
                    });


                    this.usinas = options;

                })
                .catch((error) => {
                    console.log(error);
                });
        },
        listarTipos() {
            this.axios
                .post("/tipos-faturas/listar")
                .then((response) => {
                    console.log(response);
                    let options = [];
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["titulo"] };
                        options.push(option);
                    });
                    this.tipos = options;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        listarRelatorio() {
            this.axios
                .post("/eum/economia")
                .then((response) => {
                    this.economia = response.data.dados;
                    this.total_volume_mwp = response.data.total_volume_mwp;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        formatCurrency(value) {
            if (value)
                return value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                });
            return;
        },
        openNew() {
            this.unidade = {};
            this.submitted = false;
            this.productDialog = true;
        },
        hideDialog() {
            this.productDialog = false;
            this.submitted = false;
        },
        onChangeFileUpload() {
            this.arquivo = this.$refs.arquivo.files[0];
        },
        salvarFatura() {
            this.submitted = true;

            let formData = new FormData();
            formData.append("arquivo", this.arquivo);

            let dados = {
                id_empresa: this.id_empresa,
                id_unidade: this.id_unidade,
                id_tipo: this.id_tipo,
                data_referencia: this.data_referencia,
                data_vencimento: this.data_vencimento,
                total: this.total,
                boleto: this.boleto,
                parcela: this.parcela,
            };

            formData.append("dados", JSON.stringify(dados));

            if (this.unidade.id) {
                this.axios
                    .post("/unidades/editar", this.unidade)
                    .then(() => {
                        this.listarFatura();
                        this.productDialog = false;
                        this.unidade = {};
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                this.axios
                    .post("/faturas/adicionar", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(() => {
                        this.listarFatura();
                        this.productDialog = false;
                        this.unidade = {};
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },
        download(fatura) {
            let url = { ...fatura }
            window.open(url.link + "/" + this.token);
        },
        editProduct(fatura) {
            this.productDialog = true;
            this.fatura = { ...fatura };
            this.id_empresa = this.fatura.id_empresa;
            this.id_unidade = this.fatura.id_unidade;
            this.id_tipo = this.fatura.id_tipo;
            this.data_referencia = this.fatura.data_referencia;
            this.data_vencimento = this.fatura.data_vencimento;
            this.total = this.fatura.total;
            this.boleto = this.fatura.boleto;
            this.parcela = this.fatura.parcela;
        },

        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteProductsDialog = true;
        },
        deleteSelectedProducts() {
            let obj = this.selectedProducts;
            Object.keys(obj).forEach((key) => {
                this.axios
                    .post("/unidades/apagar", obj[key])
                    .then(() => {
                        this.listarFatura();
                        this.deleteProductDialog = false;
                        this.unidade = {};
                        this.$toast.add({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Usário apagado com sucesso!s",
                            life: 3000,
                        });
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            });

            this.deleteProductsDialog = false;
            this.selectedProducts = null;
            this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Faturas apagada com sucesso!",
                life: 3000,
            });
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
  
<style scoped>
.table th {
    padding: 10px;
    font-weight: bold;
    text-align: left;
    background: #eee;
}

.table td {
    padding: 5px;
    font-weight: 100;
    white-space: nowrap;


}

.table input {
    width: 50px;
    height: 25px;
    ;
    border: none;
    border-radius: 5px;
}

.mes_selecionado {
    background: #ccc !important;
    font-weight: bold !important;
}
</style>
  